import React from "react"

// Components
import Seo from "../components/seo"

//Figures
import windmill from "../images/windmill.svg"
import htmlIcon from "../images/icons/html-icon.svg"
import reactIcon from "../images/icons/react-icon.svg"
import twIcon from "../images/icons/tailwind-icon.svg"

export default function ComingSoonPage() {
  return (
    <>
      <Seo
        title="Tailwind CSS - Coming Soon"
        ogtitle="Tailwind CSS - Coming Soon"
        description="Evolutionary and accessible UI components built with Tailwind"
        ogdescription="Evolutionary and accessible UI components built with Tailwind."
        author="Wind UI"
        keywords="Wind UI, Tailwind, CSS, Component, Accessible, Coming Soon"
        robots="noindex,nofollow"
        revisit_after="7 days"
      />
      <div className="h-[90vh]">
        <div className="container flex flex-col items-center content-center justify-center h-full m-auto">
          <img
            src={windmill}
            className="mb-8 h-[89px] w-[89px] animate-spin-slow"
          />
          <h1 className="mb-2 text-5xl font-bold text-wuiSlate-700">
            Wind<span className="text-wuiEmerald-500">UI</span>
          </h1>
          <p className="text-2xl text-center text-wuiSlate-600">
            Nimble, Accessible,
          </p>
          <p className="mb-4 text-2xl text-center text-wuiSlate-600">
            The evolutionary user interface design kit.
          </p>
          <div className="flex items-center justify-center mb-4 gap-11">
            <img className="w-12 h-12" src={htmlIcon} />
            <img className="w-12 h-12" src={twIcon} />
            <img className="w-12 h-12" src={reactIcon} />
          </div>
          <p className="font-bold text-wuiSlate-700">
            Coming <span className="text-wuiEmerald-500">Soon</span>
          </p>
        </div>
      </div>
    </>
  )
}
